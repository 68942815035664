<template>
  <CSidebar
    class="small"
    :minimize="minimize"
    :unfoldable="false"
    :show="show"
    size="sm"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/" style="background-color: #151515;">
      <CRow>
        <CCol class="text-right" md="7" v-show="!minimize">
          <img src="img/logo-new.png" width="80%"/>
        </CCol>
        <CCol class="text-left">
          <CBadge color="info">
            Drive
          </CBadge>
        </CCol>
      </CRow>
    </CSidebarBrand>

    <CRenderFunction :flat="true"  :contentToRender="[navMenu]"/>
    <CSidebarMinimizer
      class="c-d-md-down-none"
      @click.native="$store.commit('toggle', 'sidebarMinimize')"
    />
  </CSidebar>
</template>

<script>
import {apiCall} from '../common/utils';

export default {
  name: 'TheSidebar',
  computed: {
    show () {
      return this.$store.state.sidebarShow
    },
    minimize () {
      return this.$store.state.sidebarMinimize
    }
  },
  data(){
    return {
      navMenu: {
        _name: 'CSidebarNav',
        _children: [],
      },

    }
  },
  async created(){
    console.log( "TheSideBar---------> created ----");
    try{
      this.user = this.$store.state.user;

      const rs = await apiCall('GET', '/api/access/menu');
      if( rs.code===200 ){
        this.navMenu._children = rs.result;
      }else{
        console.log(rs);
      }

    }catch(err){
      console.log(err);
    }

  },
}
</script>

