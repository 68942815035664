<template>
  <CHeader with-subheader>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />

    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <!--      <CIcon name="logo" height="48" alt="Logo"/>-->
      <img src="img/logo-new.png" width="100"/>
      <CBadge color="dark">탁송관리 시스템</CBadge>
    </CHeaderBrand>

    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <button
          @click="() => $store.commit('toggle', 'soundOn')"
          class="c-header-nav-btn"
        >
          <BButton size="sm"
                   :variant="$store.state.soundOn?'outline-info':'secondary'">
            <CIcon v-if="$store.state.soundOn" name="cil-volume-high"/>
            <CIcon v-else name="cil-volume-off"/>
            <span class="small ml-2"><b>SOUND {{$store.state.soundOn?'ON':'OFF'}}</b></span>
          </BButton>

        </button>
      </CHeaderNavItem>
    </CHeaderNav>




    <BFormCheckbox class="mt-3 mr-2"
                   v-model="$store.state.isDrvOn"
                   @change="toggleDrvSound"
                   switch> 탁송알람</BFormCheckbox>

    <BFormCheckbox class="mt-3 mr-2" v-model="$store.state.isMsgOn"
                   @change="toggleMsgSound"
                   switch> 메세지알람</BFormCheckbox>



    <CHeaderNav class="mr-4">
      <CHeaderNavItem class="d-md-down-none mx-2"/>
      <TheHeaderDropdownAccnt :key="accountKey"/>
      <BNavItemDropdown size="sm" id="user-nav" :text="user.nickName" class="mr-3">
        <BDropdownItem @click="clickUserInfo">정보 변경</BDropdownItem>
        <BDropdownDivider/>
        <BDropdownItem @click="clickLogout">로그아웃</BDropdownItem>
      </BNavItemDropdown>

      <!-- Right Aside Toggle Btn-->
      <CToggler
        in-header
        class="ml-3 d-lg-none mr-4"
        @click="$store.commit('toggleMessageBarMobile')"
      />

      <CToggler
        in-header
        class="ml-3 d-md-down-none mr-4"
        @click="$store.commit('toggleMessageBarDesktop')"
      />

    </CHeaderNav>
    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0"/>
    </CSubheader>

  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import {audioEnable, beepSound, speech} from '../common/utils'
// import {audioContextResume} from "@/common/utils";

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt
  },
  data(){
    return {
      isDrvSound: this.$store.state.isDrvOn,
      isMsgSound: this.$store.state.isMsgOn,
      user: this.$store.state.user,
      accountKey: 0,
      timer: null,
      totalSecs: this.$store.state.user.tokenExpireMin * 60,
      btnDisabled: true,
    }

  },
  created(){
    console.log( "-------------------------- TheHeader created ------------------------ ");

    console.log( '---- ers,msg ------>', this.isDrvSound, this.isMsgSound )

    // this.user = $user;
    this.accountKey = Math.floor((Math.random() * 100000));

  },
  computed:{
  },
  methods: {


    padTime: function(time) {
      return (time < 10 ? '0' : '') + time;
    },


    toggleDrvSound: async function(){
      this.$store.state.isDrvOn = !this.$store.state.isDrvOn
      if( this.$store.state.isDrvOn===true ){
        this.$store.state.soundOn = true;
        await audioEnable();
        await beepSound(1);
        speech('탁송 알람이 켜졌습니다.')

      }else{
        this.$store.state.soundOn = false;
      }
      console.log( '---- isErsOn', this.$store.state.isDrvOn )
    },
    toggleMsgSound: async function(){
      this.$store.state.isMsgOn = !this.$store.state.isMsgOn
      if( this.$store.state.isMsgOn===true ){
        await audioEnable();
        await beepSound(2);
        speech('메세지 알람이 켜졌습니다.')
      }
      console.log( '---- isMsgOn', this.$store.state.isMsgOn )
    },

    clickLogout : async function(){
      await this.$store.dispatch('LOGOUT');
      setTimeout( async ()=>await this.$router.push({ path: '/login'  }), 300 );
    },

    clickUserInfo: function() {
      this.$router.push({ path: '/setting/user-info'  });
    },

  },
  beforeDestroy() {
    console.log('--------------- dashboard: beforeDestroy( ) --------------- ');
  }
}
</script>
