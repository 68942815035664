<template>
  <CDropdown
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0">
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img
            :src="avatarUrl"
            class="c-avatar-img "
          />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>
        {{user.name}}
        <BBadge :variant="(user.role=='CD')?'warning':'primary'">{{displayRole}}</BBadge>
      </strong>
    </CDropdownHeader>
    <CDropdownHeader tag="div" class="text-center" color="light">
      아이디 <BBadge variant="info"><strong>{{user.id}}</strong></BBadge>
    </CDropdownHeader>

  </CDropdown>
</template>

<script>
const roleMap = {
  CS: '수퍼바이저',
  CD: '딜러',
  CT: '상담사'
};

export default {
  name: 'TheHeaderDropdownAccnt',
  data () {
    return {
      user: null,
      itemsCount: 42,
      avatarUrl: '',
      displayRole: null,

    }
  },
  created(){
    this.user = this.$store.state.user;
    this.displayRole = roleMap[this.user.role];
    // switch( this.user.role ) {
    //     case 'OA': this.displayRole = '관리자'; break;
    //     case 'OM': this.displayRole = '담당자'; break;
    //     case 'OS': this.displayRole = '사용자'; break;
    //     default: this.displayRole = 'Unknown'; break;
    // }

    //this.avatarUrl = ($user.userAvatar)?$baseURL+'/file/'+$user.userAvatar: '/images/default-avatar.png';
    this.avatarUrl = "/img/avatars/6.jpg";
    // console.log( "TheHeaderDropdownAccnt-------------------------------avatarUrl", this.avatarUrl );
  }
}
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>
