<template>
  <CFooter :fixed="false">
    <div>
      <a href="http://www.chungbang.com" target="_blank">ChungBang</a>
      <span class="ml-1">&copy; {{new Date().getFullYear()}} drive office</span>
    </div>
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">Powered by</span>
      <a href="https://www.iotels.me" target_blank>IoTels</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
